<template>
    <div class="bg-black text-gray-200" id="myProjects">
        <h4 class="text-3xl md:text-5xl text-gray-300 font-bold pt-8 mb-6">Meine Projekte ...</h4>
        <div class="flex pb-12 md:mb-0">
            <div class="container m-auto">
                <div
                        class="flex flex-col md:grid grid-cols-9 mx-auto p-2 text-black"
                >
                  <!-- left -->
                  <div class="flex flex-row-reverse md:contents">
                    <div
                        class="bg-gray-300 col-start-1 col-end-5 p-4 rounded-xl my-4 shadow-md"
                    >
                      <a href="https://ostend.digital/">
                        <h3 class="font-semibold text-lg mb-1 underline">Ostend Digital - Freelance</h3>
                      </a>
                      <p class="font-bold text-left py-2">Tätigkeiten:</p>
                      <p class="leading-tight text-justify">
                        - Migrieren von Daten von STEPS ERP/ Microsoft SQL Server 2012 zu Shopware 6
                        <br>
                        - Konzeptionierung von gesamten Datenfluss zwischen Applikationen
                      </p>
                      <p class="font-bold text-left py-2">Technologien:</p>
                      <div class="grid grid-cols-2 md:grid-cols-4 gap-1 text-sm">
                        <div class="bg-gray-900 rounded text-gray-100 px-2">PHP 8</div>
                        <div class="bg-gray-900 rounded text-gray-100 px-2">Shopware 6</div>
                        <div class="bg-gray-900 rounded text-gray-100 px-2">Steps</div>
                        <div class="bg-gray-900 rounded text-gray-100 px-2">MS Business Central</div>
                        <div class="bg-gray-900 rounded text-gray-100 px-2">MySQL</div>
                      </div>
                      <p class="leading-tight text-center pt-2">
                        ab 2024 Juli - September 2024
                      </p>
                    </div>
                    <div class="col-start-5 col-end-6 md:mx-auto relative mr-10">
                      <div class="h-full w-6 flex items-center justify-center">
                        <div class="h-full w-1 bg-gray-300 pointer-events-none"></div>
                      </div>
                      <div
                          class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-300 shadow"
                      ></div>
                    </div>
                  </div>

                    <!-- right -->
                  <div class="flex md:contents">
                    <div class="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                      <div class="h-full w-6 flex items-center justify-center">
                        <div class="h-full w-1 bg-gray-300 pointer-events-none"></div>
                      </div>
                      <div
                          class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-300 shadow"
                      ></div>
                    </div>
                    <div
                        class="bg-gray-300 col-start-6 col-end-10 p-4 rounded-xl my-4 md:mr-auto shadow-md"
                    >
                      <a href="https://simplerecruit.de">
                        <h3 class="font-semibold text-lg mb-1 underline">Gartenhaus GmbH/ Ydeon group - Freelance</h3>
                      </a>
                      <p class="leading-tight text-justify">
                        - Konzeption & Entwicklung von Datahubs mit einer Serverless Architektur basierend auf Symfony, Serverless, AWS &
                        Terraform
                        <br>
                        - Implementierung eines neuen shops in shopware 6
                      </p>
                      <p class="font-bold text-left py-2">Technologien:</p>
                      <div class="grid grid-cols-2 md:grid-cols-4 gap-1 text-sm">
                        <div class="bg-gray-900 rounded text-gray-100 px-2">PHP 8.2</div>
                        <div class="bg-gray-900 rounded text-gray-100 px-2">Serverless</div>
                        <div class="bg-gray-900 rounded text-gray-100 px-2">AWS Lambda</div>
                        <div class="bg-gray-900 rounded text-gray-100 px-2">Terraform</div>
                        <div class="bg-gray-900 rounded text-gray-100 px-2">AWS ECS</div>
                        <div class="bg-gray-900 rounded text-gray-100 px-2">AWS SQS</div>
                        <div class="bg-gray-900 rounded text-gray-100 px-2">AWS SNS</div>
                        <div class="bg-gray-900 rounded text-gray-100 px-2">AWS S3</div>
                        <div class="bg-gray-900 rounded text-gray-100 px-2">Docker</div>
                        <div class="bg-gray-900 rounded text-gray-100 px-2">Sentry</div>
                        <div class="bg-gray-900 rounded text-gray-100 px-2">Github CI\CD</div>
                        <div class="bg-gray-900 rounded text-gray-100 px-2">Bitbucket CI\CD</div>
                        <div class="bg-gray-900 rounded text-gray-100 px-2">Cloudwatch</div>
                        <div class="bg-gray-900 rounded text-gray-100 px-2">MySQL</div>
                        <div class="bg-gray-900 rounded text-gray-100 px-2">Shopware 6</div>
                      </div>
                      <p class="leading-tight text-center pt-2">
                        ab April 2023 - September 2024
                      </p>
                    </div>
                  </div>
                    <!-- left -->
                    <div class="flex flex-row-reverse md:contents">
                        <div
                                class="bg-gray-300 col-start-1 col-end-5 p-4 rounded-xl my-4 shadow-md"
                        >
                            <a href="https://onecompanion.de/">
                                <h3 class="font-semibold text-lg mb-1 underline">One Companion - Freelance</h3>
                            </a>
                            <p class="font-bold text-left py-2">Tätigkeiten:</p>
                            <p class="leading-tight text-justify">
                                - Solo Entwicklung einer gesamten MVP Applikation
                                <br>
                                - Monolithische Laravel MVC-Applikation auf AWS
                                <br>
                                - Umsetzung mit dem TALL Stack (Tailwind CSS, Alpine.js, Laravel Livewire, Laravel)
                            </p>
                            <p class="font-bold text-left py-2">Technologien:</p>
                            <div class="grid grid-cols-2 md:grid-cols-4 gap-1 text-sm">
                                <div class="bg-gray-900 rounded text-gray-100 px-2">PHP 8</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Laravel</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">PHPUnit</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">AWS ECS</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">AWS SQS</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">AWS SNS</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">AWS S3</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Docker</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Cloudformation</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Github CI\CD</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Cloudwatch</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">MySQL</div>
                            </div>
                            <p class="leading-tight text-center pt-2">
                                ab 2023 April - Juni 2023
                            </p>
                        </div>
                        <div class="col-start-5 col-end-6 md:mx-auto relative mr-10">
                            <div class="h-full w-6 flex items-center justify-center">
                                <div class="h-full w-1 bg-gray-300 pointer-events-none"></div>
                            </div>
                            <div
                                    class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-300 shadow"
                            ></div>
                        </div>
                    </div>

                    <!-- right -->
                    <div class="flex md:contents">
                        <div class="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                            <div class="h-full w-6 flex items-center justify-center">
                                <div class="h-full w-1 bg-gray-300 pointer-events-none"></div>
                            </div>
                            <div
                                    class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-300 shadow"
                            ></div>
                        </div>
                        <div
                                class="bg-gray-300 col-start-6 col-end-10 p-4 rounded-xl my-4 shadow-md"
                        >
                            <a href="https://procuros.io/de">
                                <h3 class="font-semibold text-lg mb-1 underline">Procuros</h3>
                            </a>
                            <p class="font-bold text-left py-2">Tätigkeiten:</p>
                            <p class="leading-tight text-justify">
                                - Konzeption & Entwicklung einer EDI Messanger Plattform
                                <br>
                                - Programmierung von Connectoren in PHP
                                <br>
                                - Entwicklung von REST APIs für das neue Procuros Portal
                            </p>
                            <p class="font-bold text-left py-2">Technologien:</p>
                            <div class="grid grid-cols-2 md:grid-cols-4 gap-1 text-sm">
                                <div class="bg-gray-900 rounded text-gray-100 px-2">PHP 8</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Laravel</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">PHPUnit</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">AWS ECS</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">AWS SQS</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">AWS SNS</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">AWS S3</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Docker</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">New Relic</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Gitlab CI\CD</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Cloudwatch</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">MySQL</div>
                            </div>
                            <p class="leading-tight text-center pt-2">
                                ab März 2022 - April 2023
                            </p>
                        </div>
                    </div>

                    <!-- right -->
                    <!--
                    <div class="flex md:contents">
                        <div class="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                            <div class="h-full w-6 flex items-center justify-center">
                                <div class="h-full w-1 bg-gray-300 pointer-events-none"></div>
                            </div>
                            <div
                                    class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-300 shadow"
                            ></div>
                        </div>
                        <div
                                class="bg-gray-300 col-start-6 col-end-10 p-4 rounded-xl my-4 shadow-md"
                        >
                            <a href="https://simplerecruit.de">
                                <h3 class="font-semibold text-lg mb-1 underline">SimpleRecruit</h3>
                            </a>
                            <p class="leading-tight text-justify">
                                Entwicklung einer provisionsbasierten IT-Recruitmentplattform
                            </p>
                            <p class="font-bold text-left py-2">Technologien:</p>
                            <div class="grid grid-cols-2 md:grid-cols-4 gap-1 text-sm">
                                <div class="bg-gray-900 rounded text-gray-100 px-2">PHP 8</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Laravel</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Laravel Livewire</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Tailwind CSS</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">AWS EC2</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">AWS SQS</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">AWS SNS</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">AWS S3</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Docker</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Sentry</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Github CI\CD</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">MySQL</div>
                            </div>
                            <p class="leading-tight text-center pt-2">
                                ab März 2022
                            </p>
                        </div>
                    </div>
                    -->
                    <!-- left -->
                    <div class="flex flex-row-reverse md:contents">
                        <div
                                class="bg-gray-300 col-start-1 col-end-5 p-4 rounded-xl my-4 shadow-md"
                        >
                            <a href="https://www.scayle.com/">
                                <h3 class="font-semibold text-lg mb-1 underline">AboutYou SCAYLE</h3>
                            </a>
                            <p class="font-bold text-left py-2">Tätigkeiten:</p>
                            <p class="leading-tight text-justify">
                                - Entwicklung von Backend Middelwares für SCAYLE Kunden (Marc'O Polo, Tom Tailor)
                              <br>
                                - Managen von alten Backend Middlewares
                              <br>
                                - Beratung und Planung von der Entwicklung von neuer carrier connect Applikationen für mehrere SCAYLE Kunden
                            </p>
                            <p class="font-bold text-left py-2">Technologien:</p>
                            <div class="grid grid-cols-2 md:grid-cols-4 gap-1 text-sm">
                                <div class="bg-gray-900 rounded text-gray-100 px-2">PHP 8</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Laravel</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">PHPUnit</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">AWS ECS</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">AWS SQS</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">AWS SNS</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">AWS S3</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Docker</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">New Relic</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Gitlab CI\CD</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Cloudwatch</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">MySQL</div>
                            </div>
                            <p class="leading-tight text-center pt-2">
                                ab 2021 Oktober - März 2022
                            </p>
                        </div>
                        <div class="col-start-5 col-end-6 md:mx-auto relative mr-10">
                            <div class="h-full w-6 flex items-center justify-center">
                                <div class="h-full w-1 bg-gray-300 pointer-events-none"></div>
                            </div>
                            <div
                                    class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-300 shadow"
                            ></div>
                        </div>
                    </div>

                    <!-- left -->
                    <div class="flex flex-row-reverse md:contents">
                        <div
                                class="bg-gray-300 col-start-1 col-end-5 p-4 rounded-xl my-4 shadow-md"
                        >
                            <a href="https://www.aboutyou.de/">
                                <h3 class="font-semibold text-lg mb-1 underline">AboutYou Retail</h3>
                            </a>
                            <p class="font-bold text-left py-2">Tätigkeiten:</p>
                            <p class="leading-tight text-justify">
                                - Entwicklung einer neuen Logistikapplikation und Carrier-Middelware
                              <br>
                                - Instandhaltung einer legacy Logistikapplikation
                              <br>
                                - Entwicklung um die 10 Composer PHP Packages für verschiedene Lieferanten
                            </p>
                            <p class="font-bold text-left py-2">Technologien:</p>
                            <div class="grid grid-cols-2 md:grid-cols-4 gap-1 text-sm">
                                <div class="bg-gray-900 rounded text-gray-100 px-2">PHP 8</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">PHP 7.4</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Laravel</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Symfony</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">AWS</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">AWS S3</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Docker</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">New Relic</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Redis</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">MySQL</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Sentry</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">PHPUnit</div>
                            </div>
                            <p class="leading-tight text-center pt-2">
                                ab 2020 März - 2021 Oktober
                            </p>
                        </div>
                        <div class="col-start-5 col-end-6 md:mx-auto relative mr-10">
                            <div class="h-full w-6 flex items-center justify-center">
                                <div class="h-full w-1 bg-gray-300 pointer-events-none"></div>
                            </div>
                            <div
                                    class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-300 shadow"
                            ></div>
                        </div>
                    </div>
                    <!-- right -->
                    <div class="flex md:contents">
                        <div class="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                            <div class="h-full w-6 flex items-center justify-center">
                                <div class="h-full w-1 bg-gray-300 pointer-events-none"></div>
                            </div>
                            <div
                                    class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-300 shadow"
                            ></div>
                        </div>
                        <div
                                class="bg-gray-300 col-start-6 col-end-10 p-4 rounded-xl my-4 shadow-md"
                        >
                            <a href="https://www.upaxis.com/">
                                <h3 class="font-semibold text-lg mb-1 underline">Hq Software Solutions UG</h3>
                            </a>
                            <p class="leading-tight text-justify">
                                - Entwicklung einer cloudbasierten Performance Marketing Plattform
                              <br>
                                - Umsetzung mit dem LAMP Stack
                              <br>
                                - Implementierung von AWS Redshift
                            </p>
                            <div class="grid grid-cols-2 md:grid-cols-4 gap-1 text-sm py-2">
                                <div class="bg-gray-900 rounded text-gray-100 px-2">PHP 7.2</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Aws Elastic Beanstalk</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">jQuery</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">HTML</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">CSS</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Bootstrap</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">Laravel</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">javaScript</div>
                                <div class="bg-gray-900 rounded text-gray-100 px-2">AWS Redshift</div>
                            </div>
                            <p class="leading-tight text-center pt-2">
                                ab 2018 Januar - 2020 März
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MyProjects"
}
</script>