var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"AufrufVonAWSModelle"},[_c('div',{staticClass:"bg-black"},[_c('div',{staticClass:"text-white text-left max-w-3xl mx-auto py-12 md:py-24 px-4 sm:px-6 xl:max-w-5xl xl:px-0"},[_c(_setup.ArticleHeader,{attrs:{"date":_vm.articleHeaderDate,"title":_vm.articleHeaderTitel,"text":_vm.articleHeaderText}}),_vm._m(0),_c('p',{staticClass:"my-2"},[_vm._v(" Ereignisquellen können eine Lambda-Funktion in drei allgemeinen Mustern aufrufen. Diese Muster werden Aufrufmodelle genannt. Jedes Aufrufmodell ist einzigartig und deckt unterschiedliche Anwendungs- und Entwickleranforderungen ab. Das Aufrufmodell, das Sie für Ihre Lambda-Funktion verwenden, hängt häufig von der Ereignisquelle ab, die Sie verwenden. Es ist wichtig zu verstehen, wie jedes Aufrufmodell Funktionen initialisiert und Fehler sowie Wiederholungen behandelt. ")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"my-4"},[_c('h2',{staticClass:"text-3xl font-bold"},[_vm._v("Was sind Aufrufmodelle? ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"my-4"},[_c('h2',{staticClass:"text-3xl font-bold"},[_vm._v("Die drei Aufrufmodelle:")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"my-4"},[_c('h3',{staticClass:"text-2xl font-bold"},[_vm._v("1. Synchroner Aufruf")]),_c('p',[_vm._v(" Synchroner Aufruf ist ein Aufrufmodell, bei dem die Lambda-Funktion auf das Ergebnis des Aufrufs wartet, bevor sie mit der Ausführung beginnt. Synchroner Aufruf ist nützlich, wenn Sie eine Lambda-Funktion ausführen möchten, die auf das Ergebnis des Aufrufs wartet, bevor sie mit der Ausführung beginnt. Beispielsweise können Sie eine Lambda-Funktion synchron ausführen, um eine Datei in Amazon S3 zu speichern und dann das Ergebnis an Amazon SNS zu senden. ")]),_c('div',{staticClass:"py-8"},[_c('img',{staticClass:"w-full md:w-64",attrs:{"src":"/img/schema/synchron.png"}})]),_c('p',[_vm._v(" Folgende Services unterstützen den Aufruf von AWS Lambda Synchron:")]),_c('ul',{staticClass:"list-disc mx-8"},[_c('li',[_vm._v("Amazon Api Gateway")]),_c('li',[_vm._v("Amazon Cognito")]),_c('li',[_vm._v("AWS Cloudformation")]),_c('li',[_vm._v("Amazon Alexa")]),_c('li',[_vm._v("Amazon Lex")]),_c('li',[_vm._v("Amazon Cloudfront")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"my-4"},[_c('h3',{staticClass:"text-2xl font-bold"},[_vm._v("2. Asynchroner Aufruf")]),_c('p',[_vm._v(" Asynchrone Aufrufe sind asynchron, was bedeutet, dass die Lambda-Funktion nicht auf das Ergebnis des Aufrufs wartet. Die Lambda-Funktion wird asynchron ausgeführt und kann das Ergebnis des Aufrufs an eine andere Anwendung oder einen anderen Dienst senden. Asynchrone Aufrufe sind nützlich, wenn Sie eine Lambda-Funktion ausführen möchten, ohne auf das Ergebnis zu warten. Beispielsweise können Sie eine Lambda-Funktion asynchron ausführen, um eine Datei in Amazon S3 zu speichern und dann das Ergebnis an Amazon SNS zu senden. ")]),_c('div',{staticClass:"py-2"},[_c('img',{staticClass:"w-full md:w-64",attrs:{"src":"/img/schema/asynchron.png"}})]),_c('p',{staticClass:"my-2"},[_vm._v("Folgende Services rufen AWS Lambda asynchron auf:")]),_c('ul',{staticClass:"list-disc mx-8"},[_c('li',[_vm._v("Amazon SNS")]),_c('li',[_vm._v("Amazon S3")]),_c('li',[_vm._v("Amazon EventBridge")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"my-4"},[_c('h3',{staticClass:"text-2xl font-bold"},[_vm._v("3. Polling Aufruf")]),_c('p',[_vm._v(" Polling-Aufrufe sind eine spezielle Art von asynchronen Aufrufen, bei denen die Lambda-Funktion periodisch ausgeführt wird, um nach neuen Daten zu suchen. Polling-Aufrufe sind nützlich, wenn Sie periodisch nach neuen Daten suchen und dann eine Aktion ausführen möchten. Beispielsweise können Sie eine Lambda-Funktion periodisch ausführen, um nach neuen Daten in einer Datenbank zu suchen und dann das Ergebnis an eine andere Anwendung oder einen anderen Dienst senden. ")]),_c('p',{staticClass:"my-2"},[_vm._v("Folgende Services unterstützen AWS Lambda polling:")]),_c('ul',{staticClass:"list-disc mx-8"},[_c('li',[_vm._v("Amazon Kinesis")]),_c('li',[_vm._v("Amazon SQS")]),_c('li',[_vm._v("Amazon DynamoDB Streams")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"my-4"},[_c('h2',{staticClass:"text-3xl font-bold"},[_vm._v("Wann soll welches Modell verwendet werden? ")]),_c('p',[_vm._v(" Die Auswahl des richtigen Aufrufmodells hängt von der Art der Anwendung und den Anforderungen des Entwicklers ab. Synchroner Aufruf ist nützlich, wenn Sie auf das Ergebnis des Aufrufs warten müssen, bevor Sie mit der Ausführung beginnen. Asynchrone Aufrufe sind nützlich, wenn Sie eine Lambda-Funktion ausführen möchten, ohne auf das Ergebnis zu warten. Polling-Aufrufe sind nützlich, wenn Sie periodisch nach neuen Daten suchen und dann eine Aktion ausführen möchten. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"my-4"},[_c('h2',{staticClass:"text-3xl font-bold"},[_vm._v("Aufrufmodelle einrichten")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"my-2"},[_c('p',[_vm._v(" Sie können die Aufrufmodelle für Ihre Lambda-Funktionen in der AWS Management Console, der AWS CLI oder der AWS SDK einrichten. In der AWS Management Console können Sie die Aufrufmodelle für Ihre Lambda-Funktion in der Konfigurationseinstellung \"Aufrufmodell\" einrichten. In der AWS CLI können Sie die Aufrufmodelle für Ihre Lambda-Funktion mit dem Befehl \"aws lambda update-function-configuration\" einrichten. In der AWS SDK können Sie die Aufrufmodelle für Ihre Lambda-Funktion mit der Methode \"updateFunctionConfiguration\" einrichten. ")])])
}]

export { render, staticRenderFns }