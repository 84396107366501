<template>
  <div class="customThirdColorFadeGrayBlack" id="contact">
      <h4 class="text-3xl md:text-5xl font-bold pt-8 mb-6">Kontakt</h4>
      <div class="flex py-12 md:py-32">
      <div class="m-auto">
        <div class="grid grid-cols-1 md:grid-cols-4 gap-4 ">
          <div>
            <a href="https://github.com/ThomasAKaestner">
              <img class="w-16 pb-2 m-auto" src="img/icons/github.png">
              <p class="text-center">Github</p>
            </a>
          </div>
          <div>
            <a href="https://de.linkedin.com/in/thomas-alan-k%C3%A4stner-931b67199">
              <img class="w-16 pb-2 m-auto" src="img/icons/linkedin.png">
              <p class="text-center">LinkedIn</p>
            </a>
          </div>
          <div>
            <a href="https://www.xing.com/profile/ThomasAlan_Kaestner2">
              <img class="w-16 pb-2 m-auto" src="img/icons/xing.png">
              <p class="text-center">Xing</p>
            </a>
          </div>
          <div>
            <a href = "mailto: freelancing.thomasalankaestner@gmail.com">
              <img class="w-16 pb-2 m-auto" src="img/icons/mail.png">
              <p class="text-center">Email</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped>

</style>