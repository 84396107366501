<template>
    <div class="customThirdColorFade" id="products">
        <div class="flex flex-col h-auto px-4 md:px-32 lg:px-64">
            <h4 class="text-3xl md:text-5xl customThirdTextColor font-bold mb-6">Was ich anbiete ...</h4>

            <div class="py-12 w-full text-gray-300">
                <div class="grid grid-cols-1 md:grid-cols-3 gap-4 h-auto md:py-32">
                    <div class="border p-4  bg-black">
                        <h3 class="pb-4 text-xl font-bold">Backend Entwicklung</h3>
                        <div>
                            Als erfahrener Backend-Entwickler biete ich maßgeschneiderte Lösungen in den Bereichen <b>PHP,
                            Symfony, Laravel und Golang </b>an, unabhängig von der Architektur, sei es <b>Microservices</b> oder
                            <b> monolithische Anwendungen</b>. Mit tiefgreifendem Fachwissen entwickle ich zuverlässige und
                            effiziente Backend-Systeme, um individuelle Anforderungen zu erfüllen.
                        </div>
                        <div class="border p-4 m-4 cursor-pointer customColor">
                            <a href="#contact">Jetzt Angebot anfordern</a>
                        </div>
                    </div>
                    <div class="border p-4 bg-black">
                        <h3 class="pb-4 text-xl font-bold">AWS Architektur & DevOps</h3>
                        <div>
                            Als <b>AWS Cloud Architect & AWS Developer</b> bringe ich umfassende Expertise in die Gestaltung
                            und Umsetzung Cloud-basierter Lösungen. Mit fundiertem Wissen über AWS-Technologien
                            entwickle ich maßgeschneiderte Architekturen, die höchste Performance, Skalierbarkeit und
                            Sicherheit gewährleisten. Vom Design bis zur Implementierung stehe ich für innovative
                            Cloud-Lösungen, um geschäftliche Anforderungen optimal zu adressieren.
                        </div>
                        <div class="border p-4 m-4 cursor-pointer">
                            <a href="#contact">Jetzt Angebot anfordern</a>
                        </div>
                    </div>
                    <div class="border p-4 bg-black">
                        <h3 class="pb-4 text-xl font-bold">Shopware 6 Entwicklung & Beratung</h3>
                        <div>

                            Als erfahrener Entwickler biete ich die nahtlose Anbindung deines Shop-Systems an
                            verschiedene externe Dienste an, gepaart mit effektiver Automatisierung. Von der
                            reibungslosen Zahlungsabwicklung mit Stripe und PayPal bis zur automatisierten
                            Versandoptimierung durch UPS, DHL und FedEx. Dein Shop wird mit Marketingtools wie Mailchimp und
                            Facebook Business nahtlos verknüpft. Automatisierte Produktbewertungen mit Yotpo und
                            effiziente Lagerverwaltung durch Zoho Inventory sind ebenso Teil meines Angebots. Durch
                            gezielte Automatisierung revolutioniere ich deine Abläufe, damit dein Business nicht nur
                            effizienter, sondern auch erfolgreicher agieren kann.
                        </div>
                        <div class="border p-4 m-4 cursor-pointer">
                            <a href="#">mehr erfahren</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MyProducts"
}
</script>

<style scoped>

</style>