var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"AWSEC2NetzwerkOptionen"},[_c('div',{staticClass:"bg-black"},[_c('div',{staticClass:"text-white text-left max-w-3xl mx-auto py-12 md:py-24 px-4 sm:px-6 xl:max-w-5xl xl:px-0"},[_c(_setup.ArticleHeader,{attrs:{"date":_vm.articleHeaderDate,"title":_vm.articleHeaderTitel,"text":_vm.articleHeaderText}}),_c('p',[_vm._v(" Eine Elastische Netzwerkschnittstelle ist eine logische Netzwerkkomponente, die eine virtuelle Netzwerkkarte für eine EC2-Instanz darstellt. Wie für alle Netzwerkkarten gilt, können Sie eine Verbindung zu einem oder mehreren Netzwerken herstellen, Daten senden und empfangen und Sicherheitsgruppen und Netzwerkzugriffssteuerungslisten (ACLs) anwenden. Sie ermöglicht es Ihnen, Ihre EC2-Instanzen sicher mit einem VPC (Virtual Private Cloud) zu verbinden. Es gibt zwei Arten von ENIs: primäre und sekundäre. ")]),_vm._m(0),_vm._m(1),_c('h3',{staticClass:"text-xl font-bold text-white my-4"},[_vm._v("Häufige Anwendungsfälle für eine ENI")]),_c('p',[_vm._v(" Häufige Anwendungsfälle für ENIs: ")]),_vm._m(2),_c('h3',{staticClass:"text-xl font-bold text-white my-4"},[_vm._v("Attribute einer Elastic Network Interface")]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_c('b',[_vm._v("Primäre ENIs")]),_vm._v(" sind standardmäßig kreiert, wenn Sie eine Instanz starten. Es kann nicht gelöscht werden. ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_c('b',[_vm._v("Sekundäre ENIs")]),_vm._v(" können Sie erstellen und an Ihre Instanz anhängen. Sie können eine sekundäre ENI an eine andere Instanz anhängen, wenn Sie sie nicht mehr benötigen. ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',[_c('li',[_vm._v("Bereitstellung von mehreren Netzwerkschnittstellen für eine EC2-Instanz: Dies kann nützlich sein, wenn Sie die Instanz für verschiedene Zwecke verwenden möchten, z. B. für den Webverkehr und den Datenverkehr mit der Datenbank. ")]),_c('li',[_vm._v("Migration von On-Premises-Anwendungen in die AWS-Cloud: Sie können ENIs verwenden, um die Netzwerkverbindungen Ihrer On-Premises-Anwendungen mit Ihrem VPC in der AWS-Cloud zu replizieren. ")]),_c('li',[_vm._v("Erstellung von skalierbaren und hochverfügbaren Anwendungen: Sie können ENIs verwenden, um Ihre Anwendungen auf mehrere EC2-Instanzen zu verteilen, um die Leistung und Verfügbarkeit zu verbessern. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_c('b',[_vm._v("Primäre private IPv4-Adresse:")]),_vm._v("Eine eindeutige private IPv4-Adresse aus dem Adressbereich Ihres VPC. ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_c('b',[_vm._v("Sekundäre private IPv4-Adressen (optional):")]),_vm._v(" Eine oder mehrere zusätzliche private IPv4-Adressen aus dem Adressbereich Ihres VPC. ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_c('b',[_vm._v("Elastic IP-Adresse (optional):")]),_vm._v(" Je privater IPv4-Adresse kann optional eine öffentliche IPv4-Adresse (Elastic IP) zugewiesen werden. ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_c('b',[_vm._v("Öffentliche IPv4-Adresse (optional):")]),_vm._v(" Kann der ENI direkt zugewiesen werden (ersetzt seltener verwendetes Konzept). ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_c('b',[_vm._v("IPv6-Adressen (optional):")]),_vm._v(" Eine oder mehrere IPv6-Adressen können der ENI zugewiesen werden. ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_c('b',[_vm._v("MAC-Adresse:")]),_vm._v(" Eine eindeutige Hardware-Adresse der Netzwerkschnittstelle. ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_c('b',[_vm._v("Sicherheitsgruppe:")]),_vm._v("Eine ENI kann mindestens einer Sicherheitsgruppe zugeordnet werden, um den Netzwerkverkehr zu filtern. ")])
}]

export { render, staticRenderFns }